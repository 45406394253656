import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../Layout/Layout";
import PostListing from "../components/PostListing/PostListing";
import BlogPagination from "../components/BlogPagination/BlogPagination"
import config from "../../data/SiteConfig";
import "./listing.css";

class Listing extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout withSideNav>
        <div className="listing-container">
          <div className="posts-container">
            <Helmet title={config.siteTitle} />
            <PostListing postEdges={postEdges} />
          </div>
          <BlogPagination pageContext={this.props.pageContext} />
        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 600)
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
